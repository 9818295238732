import React, { useState } from 'react';
import './SeoulJuicePage.css';
import SJ_Logo from '../images/SJ_Logo.png'; // Replace with the actual path to your SJ logo image
import img1 from '../images/SJimg1.png'; // Replace with actual image paths
import img2 from '../images/SJimg2.png';
import img3 from '../images/SJimg3.png';
import img4 from '../images/SJimg4.png';
import img5 from '../images/SJimg5.png';
import img6 from '../images/SJimg6.png';
import img7 from '../images/SJimg7.png';
import img8 from '../images/SJimg8.png';
import benefitImg1 from '../images/SJbenefitimg1.png'; // Replace with actual image paths
import benefitImg2 from '../images/SJbenefitimg2.png';
import benefitImg3 from '../images/SJbenefitimg3.png';
import benefitImg4 from '../images/SJbenefitimg4.png';
import aboutImg1 from '../images/SJaboutimg1.png'; // Replace with actual image paths
import aboutImg2 from '../images/SJaboutimg2.png';
import aboutImg3 from '../images/SJaboutimg3.png';
import aboutImg4 from '../images/SJaboutimg4.png';
import SFImg1 from '../images/SJ_SFimg1.png';
import SJmerchimg1 from '../images/SJmerchimg1.png';


const rolls = {
  SJ_Logo: [img1, img2, img3, img4, img5, img6, img7, img8],
  Benefits: [benefitImg1, benefitImg2, benefitImg3, benefitImg4],
  About: [aboutImg1, aboutImg2, aboutImg3, aboutImg4],
  WheretoFind: [SFImg1],
  Buy: [img5],
  Merch: [SJmerchimg1],
};

function SeoulJuicePage() {
  const [activeRoll, setActiveRoll] = useState('SJ_Logo');

  return (
    <div className="seoul-juice-page">
      <section className="intro-section">
        <h1>Seoul Juice</h1>
        <p>Seoul Juice was a brand my brother started out of his dorm room back in 2014 because as a college athlete he had an allergic reaction to the dye found in popular sports drinks and was looking for a natural alternative. I initially supported him as his first taste tester, but in 2020 I started to help to enhance the brand's website and online presence. Despite limited experience in web development and ecommerce, I quickly learned Wix, researched competitor websites, and designed a prototype. I sought feedback and iterated the design based on input from family and friends.</p>
        <p>In January 2023 I officially joined the team part-time. Seoul Juice had started to grow in retail but not as much online. My responsibilities expanded to managing our Amazon listing and other e-commerce platforms, including Instagram, Facebook, and TikTok. After a brand redesign, I also redesigned the website, transitioned it to Shopify, and incorporated some custom features. In addition I collaborated with the social media team to lead marketing strategies and analyze e-commerce analytics, resulting in an 83% increase in our online sales.</p>
        <p>My role required continuous learning and adaptation, exemplifying my ability to manage cross-functional projects, drive user-focused design, and leverage data for strategic decision-making. I concluded my official role with Seoul Juice in July 2024 but continue to provide support and feedback whenever asked. Below is a replica of the 2023 website, showcasing my work. It is just a replica of the site so it doesn't have the functionality or dynamic content that was on the actual site other than the header.</p>
      </section>

      <section className="content-section">
        <img
          src={SJ_Logo}
          alt="SJ Logo"
          className="sj-logo"
          onClick={() => setActiveRoll('SJ_Logo')}
          style={{ cursor: 'pointer' }} // Added cursor pointer for better UX
        />
        <div className="headings">
          <h2 onClick={() => setActiveRoll('Buy')}>Buy</h2>
          <h2 onClick={() => setActiveRoll('Benefits')}>Benefits</h2>
          <h2 onClick={() => setActiveRoll('WheretoFind')}>Where to Find</h2>
          <h2 onClick={() => setActiveRoll('About')}>About</h2>
          <h2 onClick={() => setActiveRoll('Merch')}>Merch</h2>
        </div>

        <div className="roll">
          {rolls[activeRoll].map((image, index) => (
            <img key={index} src={image} alt={`Roll ${activeRoll} ${index + 1}`} className="roll-image" />
          ))}
        </div>
      </section>
    </div>
  );
}

export default SeoulJuicePage;