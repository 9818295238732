import React from 'react';
import './LyftPage.css';
import presentationPDF from '../files/LyftCaseStudy.pdf'; // Replace with actual PDF path

function LyftPage() {
  return (
    <div className="lyft-page">
      <section className="powerpoint-section">
        <h2>Lyft Case Study</h2>
        <iframe
          src={presentationPDF}
          width="90%"
          height="800px"
          title="PowerPoint Presentation"
        ></iframe>
      </section>
    </div>
  );
}

export default LyftPage;