import React from 'react';
import './TrekkoraPage.css';
import presentationPDF from '../files/Trekkora.pdf'; // Replace with actual PDF path

function TrekkoraPage() {
  return (
    <div className="trekkora-page">
      <section className="powerpoint-section">
        <h2>Trekkora Hackathon Project</h2>
        <iframe
          src={presentationPDF}
          width="90%"
          height="600px"
          title="PowerPoint Presentation"
        ></iframe>
      </section>

      <section className="prototype-section">
        <iframe
          style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
          width="1200"
          height="675"
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FHVRF0o4fYsUiGBTarmhxVj%2FUntitled%3Fnode-id%3D5-28%26t%3DdYBaBCa4iBJymSaU-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D0%253A1%26starting-point-node-id%3D5%253A28"
          allowFullScreen
          title="Figma Prototype"
        ></iframe>
      </section>
    </div>
  );
}

export default TrekkoraPage;

