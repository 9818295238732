import React from 'react';
import './ResumePage.css';
import presentationPDF from '../files/MarquesManta_Resume.pdf'; // Replace with actual PDF path

function ResumePage() {
    return (
        <div>
          <h2>Marques Manta Resume</h2>
          <iframe
            src={presentationPDF}  // Ensure this path is correct
            width="100%"
            height="800px"
            title="Resume"
          ></iframe>
        </div>
      );
    }
  

export default ResumePage;