import React from 'react';
import './PorchpairPage.css';
import porchpairLanding from '../images/porchpair_landing.jpg'; // Ensure this path is correct
import image1 from '../images/porchpair1.png'; // Replace with actual image paths
import image2 from '../images/porchpair2.png'; // Replace with actual image paths
import image3 from '../images/porchpair3.png'; // Replace with actual image paths

function PorchpairPage() {
  return (
    <div className="porchpair-page">
      <div className="porchpair-image-container">
        <img src={porchpairLanding} alt="Porchpair_landing" className="porchpair-image" />
      </div>
      <div className="porchpair-content">
        <div className="content-left">
          <h2>Porchpair</h2>
          <p></p>
          <p>In the United States, package thieves stole more than $8 billion in goods in 2023. As e-commerce continues to rise, so does that of a problem plaguing many American cities, package theft. Our mission at Porchpair is to help solve this problem, to give residents in our communities a safe, reliable harbor to send their packages and to help build the community while doing so. 
          </p>
          <p>Porchpair is an innovative service I started that tackles package theft by enabling individuals to ship their packages to a nearby business for a nominal fee, providing a convenient and secure delivery option. Porchpair partners with local businesses who can safely receive the packages for customers, generating revenue and foot traffic for the business in the process.
          </p>
          <p>When I moved to Baltimore, it was the first time I had encountered package theft and set out creating a solution for myself and the rest of the community. Thus far, Porchpair has helped keep safe hundreds of packages across the Baltimore community. 
          </p>
          <p>To build the business I started very simply. I created a quick website using Shopify and went door to door visiting local businesses to see if any would be interested in participating as a partner. After securing out first partner, High Grounds Cafe, then I had to find a way to let the community know about it. We put up flyers in the High Grounds store letting patrons know about the new service, did a in-store promotional event, and placed hundreds of door hangers in the local community. 
          </p>
          <p>Although it wasn’t a breakout success to start, I started hearing back from our first customers with how excited they were about the service. I also worked with High Grounds to improve the process for Providers and automated a few of the tasks I originally was having to do manually. Since then Porchpair has had slow, but organic growth and is now looking to expand to more businesses in the greater Baltimore area.
          </p>
        </div>
        <div className="content-right">
          <img src={image1} alt="Image 1" className="overlay-image image1" />
          <img src={image2} alt="Image 2" className="overlay-image image2" />
          <img src={image3} alt="Image 3" className="overlay-image image3" />
        </div>
      </div>
    </div>
  );
}

export default PorchpairPage;
