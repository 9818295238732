import './App.css';
import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './pages/Header';
import { Link } from 'react-router-dom';
//import Slideshow from './Slideshow';
//import Footer from './Footer'; // Import the Footer component

import PorchpairPage from './pages/PorchpairPage';
import TrekkoraPage from './pages/TrekkoraPage';
import SeoulJuicePage from './pages/SeoulJuicePage';
import LyftPage from './pages/LyftPage';

import landing from './images/Landing.png';
import PorchpairIcon from './images/PorchpairIcon.png';
import TrekkoraIcon from './images/TrekkoraIcon.png';
import SeoulJuiceIcon from './images/SeoulJuiceIcon.png';
import LyftIcon from './images/LyftIcon.png';
import ResumePage from './pages/ResumePage';


/* const slidesSection1 = [
  {
    src: '/images/image1.jpg',
    alt: 'Image 1',
    buttons: [
      { link: '/', label: 'Home' },
      { link: '/about', label: 'About' },
    ],
  },
  {
    src: '/images/image2.jpg',
    alt: 'Image 2',
    buttons: [
      { link: '/services', label: 'Services' },
      { link: '/contact', label: 'Contact' },
    ],
  },
]; */


function Home() {
  return (
    <div className="website">
      <img src= {landing} alt="Landing" className="landing-image" /> {/* Add your resized image here */}
      {/* <section className="image-section">
        <h2>Section 1</h2>
        <Slideshow slides={slidesSection1} />
      </section> */}
      <section className="grid-section">
        <div className="grid-container">
          <Link to="/PorchpairPage" className="grid-item">
          <img src={PorchpairIcon} alt="Porchpair" />
          </Link>
          <Link to="/TrekkoraPage" className="grid-item">
          <img src={TrekkoraIcon} alt="Trekkora" />
          </Link>
          <Link to="/SeoulJuicePage" className="grid-item">
          <img src={SeoulJuiceIcon} alt="Seoul Juice" />
          </Link>
          <Link to="/LyftPage" className="grid-item">
          <img src={LyftIcon} alt="Lyft" />
          </Link>
          </div>
        </section>
      <section className='spacing'></section>
      
    </div>
  );
}

// function About() {
//   return <h2>About</h2>;
// }

// function Resume() {
//   return <h2>Resume</h2>;
// }

function Contact() {
  useEffect(() => {
    window.location.href = "mailto:marquesemanta@gmail.com";
  }, []);
  
  return (
    <div>
      <h2>Redirecting to email...</h2>
    </div>
  );
}


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resume" element={<ResumePage />} />  {/* Ensure this is correct */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/PorchpairPage" element={<PorchpairPage />} />
          <Route path="/TrekkoraPage" element={<TrekkoraPage />} />
          <Route path="/SeoulJuicePage" element={<SeoulJuicePage />} />
          <Route path="/LyftPage" element={<LyftPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
