import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../images/MarquesManta_Animated.png';
import linkedinIcon from '../images/linkedin-icon.png'; // Replace with the actual path to your LinkedIn icon

function Header() {
  return (
    <header className="header">
      <div className="header-content">
        <img src={logo} alt="Marques Manta" className="header-image" />
        <nav className="header-nav">
          <Link to="/" className="header-link">
            <h1 className="header-title">Marques Manta</h1>
          </Link>
          <Link to="/resume" className="header-link">Resume</Link> {/* Updated this line */}
          <a href="mailto:marquesemanta@gmail.com" className="header-link">Contact</a>
        </nav>
        <div className="header-right">
          <a href="https://www.linkedin.com/in/marques-manta/" target="_blank" rel="noopener noreferrer" className="header-linkedin">
            <img src={linkedinIcon} alt="LinkedIn" className="linkedin-icon" />
          </a>
          <span className="built-with">Built with React by Marques Manta</span>
        </div>
      </div>
    </header>
  );
}

export default Header;
